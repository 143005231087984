/*
  For custom styles for Freightlancer, add the new scss into `/styles/freightlancer`,
  and then add it in 'freightlancer/settings/_settings.scss' to override
*/
@use "base/tailwind" as *;
@use "freightlancer/settings/settings" as *;
@use "base/normalize" as *;
@use "base/base" as *;
@use "base/hide" as *;
@use "base/read-more" as *;
@use "base/spacing" as *;
@use "vendor/vendor" as *;
@use "base/css-variables" as *;
