@forward "../../settings/settings";

/*
   Freightlancer variable overrides
   If you add anything new to "src/styles/freightlancer/settings/"
   you will need to add it here below
*/
@forward "freightlancer-colors";
@forward "colors-implementation";
@forward "variables";
