/**
 * This is the compat layer styling base file, which gets extracted to
 * its own CSS file to add webapp-compat specific global styling.
 *
 * Add the `WebappCompatWrapper` class if you expect your component to be in compat.
 */

@use "sass:meta";

/* stylelint-disable */
.WebappCompatWrapper,
.cdk-overlay-container {
  @include meta.load-css("webapp-compat-override");
  @include meta.load-css("index-freightlancer");
}
/* stylelint-enable */

// The css variables must be loaded outside of the above class to ensure they are
// loaded to the root.
@include meta.load-css("styles/base/css-variables");

/* Always import this last! This overrides styles from Material
 in order to properly set z-indexes for overlays and modals. */
@include meta.load-css("styles/vendor/absolute-global-vendor");
